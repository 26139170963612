import React from 'react';
// import CO2LandingPage from './test3';
import MoleculeLandingPage from './CO2LandingPage';

function App() {
    return (
        <div className="App">
            <MoleculeLandingPage />
        </div>
    );
}

export default App;