import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';

// Color variables
const CARBON_COLOR = 0xB0B0B0;  // Light Gray for Carbon
const OXYGEN_COLOR = 0xA7D3E0;   // Pastel Blue for Oxygen
const HYDROGEN_COLOR = 0xffffff; // White for Hydrogen
const BOND_COLOR = 0xC0C0C0;      // Silver for Bonds
// const BACKGROUND_COLOR = 0x000000; // Black Background
const PARTICLE_COLOR = 0xffffff;   // White Particles

const MoleculeLandingPage = () => {
    const mountRef = useRef(null);
    const [isCO2, setIsCO2] = useState(true);

    useEffect(() => {
        document.body.style.margin = '0';
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';

        const currentMount = mountRef.current;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        const moleculeGroup = new THREE.Group();

        const createCO2 = () => {
            moleculeGroup.clear();
            const carbonGeometry = new THREE.SphereGeometry(0.4, 32, 32);
            const carbonMaterial = new THREE.MeshPhongMaterial({ color: CARBON_COLOR });
            const carbonAtom = new THREE.Mesh(carbonGeometry, carbonMaterial);

            const oxygenGeometry = new THREE.SphereGeometry(0.3, 32, 32);
            const oxygenMaterial = new THREE.MeshPhongMaterial({ color: OXYGEN_COLOR });
            const oxygenAtom1 = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
            const oxygenAtom2 = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
            oxygenAtom1.position.x = -1;
            oxygenAtom2.position.x = 1;

            const bondGeometry = new THREE.CylinderGeometry(0.03, 0.03, 1);
            const bondMaterial = new THREE.MeshPhongMaterial({ color: BOND_COLOR });

            // Double bonds for CO2
            const bond1 = new THREE.Group();
            const bond2 = new THREE.Group();

            for (let i = 0; i < 2; i++) {
                const subBond1 = new THREE.Mesh(bondGeometry, bondMaterial);
                const subBond2 = new THREE.Mesh(bondGeometry, bondMaterial);
                subBond1.position.y = i * 0.1 - 0.05;
                subBond2.position.y = i * 0.1 - 0.05;
                subBond1.rotation.z = Math.PI / 2;
                subBond2.rotation.z = Math.PI / 2;
                bond1.add(subBond1);
                bond2.add(subBond2);
            }

            bond1.position.x = -0.5;
            bond2.position.x = 0.5;

            moleculeGroup.add(carbonAtom, oxygenAtom1, oxygenAtom2, bond1, bond2);
        };

        const createC2H4 = () => {
            moleculeGroup.clear();
            const carbonGeometry = new THREE.SphereGeometry(0.4, 32, 32);
            const carbonMaterial = new THREE.MeshPhongMaterial({ color: CARBON_COLOR });
            const carbonAtom1 = new THREE.Mesh(carbonGeometry, carbonMaterial);
            const carbonAtom2 = new THREE.Mesh(carbonGeometry, carbonMaterial);
            carbonAtom1.position.x = -0.5;
            carbonAtom2.position.x = 0.5;

            const hydrogenGeometry = new THREE.SphereGeometry(0.2, 32, 32);
            const hydrogenMaterial = new THREE.MeshPhongMaterial({ color: HYDROGEN_COLOR });
            const hydrogenAtoms = [];
            for (let i = 0; i < 4; i++) {
                const hydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
                hydrogenAtoms.push(hydrogen);
            }
            hydrogenAtoms[0].position.set(-1, 0.5, 0);
            hydrogenAtoms[1].position.set(-1, -0.5, 0);
            hydrogenAtoms[2].position.set(1, 0.5, 0);
            hydrogenAtoms[3].position.set(1, -0.5, 0);

            const bondGeometry = new THREE.CylinderGeometry(0.03, 0.03, 0.5);
            const bondMaterial = new THREE.MeshPhongMaterial({ color: BOND_COLOR });

            // Single bonds for C-H
            const chBonds = [];
            for (let i = 0; i < 4; i++) {
                const bond = new THREE.Mesh(bondGeometry, bondMaterial);
                chBonds.push(bond);
            }
            chBonds[0].position.set(-0.75, 0.25, 0);
            chBonds[1].position.set(-0.75, -0.25, 0);
            chBonds[2].position.set(0.75, 0.25, 0);
            chBonds[3].position.set(0.75, -0.25, 0);
            chBonds[0].rotation.z = Math.PI / 4;
            chBonds[1].rotation.z = -Math.PI / 4;
            chBonds[2].rotation.z = -Math.PI / 4;
            chBonds[3].rotation.z = Math.PI / 4;

            // Double bond for C=C
            const ccBond = new THREE.Group();
            for (let i = 0; i < 2; i++) {
                const subBond = new THREE.Mesh(bondGeometry, bondMaterial);
                subBond.position.y = i * 0.1 - 0.05;
                subBond.rotation.z = Math.PI / 2;
                ccBond.add(subBond);
            }

            moleculeGroup.add(carbonAtom1, carbonAtom2, ...hydrogenAtoms, ...chBonds, ccBond);
        };


        if (isCO2) {
            createCO2();
        } else {
            createC2H4();
        }

        scene.add(moleculeGroup);

        // const ambientLight = new THREE.AmbientLight(0x404040);
        const ambientLight = new THREE.AmbientLight(0x404040, 0.5);
        scene.add(ambientLight);

        const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
        scene.add(hemisphereLight);

        const pointLight = new THREE.PointLight(PARTICLE_COLOR, 1, 100);
        pointLight.position.set(10, 10, 10);
        scene.add(pointLight);

        camera.position.z = 5;

        let particlesMesh;
        if (isCO2) {
            const particlesGeometry = new THREE.BufferGeometry();
            const particlesCount = 5000;
            const posArray = new Float32Array(particlesCount * 3);

            for (let i = 0; i < particlesCount * 3; i++) {
                posArray[i] = (Math.random() - 0.5) * 20; // Increased range to spread particles
            }

            particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
            const particlesMaterial = new THREE.PointsMaterial({
                size: 0.005,
                color: 0xffffff,
            });

            particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
            particlesMesh.position.z = -5; // Move particles behind the molecule
            scene.add(particlesMesh);
        }

        const animate = () => {
            requestAnimationFrame(animate);
            moleculeGroup.rotation.y += 0.005;
            moleculeGroup.rotation.x += 0.002;
            if (particlesMesh) {
                particlesMesh.rotation.y += 0.0002;
            }
            renderer.render(scene, camera);
        };

        animate();

        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            // Adjust FOV for mobile devices
            const aspect = window.innerWidth / window.innerHeight;
            const fov = aspect > 1 ? 75 : 100;
            camera.fov = fov;
            camera.updateProjectionMatrix();

            // Adjust molecule size for smaller screens
            const scaleFactor = window.innerWidth < 768 ? 0.7 : 1;
            moleculeGroup.scale.set(scaleFactor, scaleFactor, scaleFactor);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        const onMouseClick = (event) => {
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(moleculeGroup.children);

            if (intersects.length > 0) {
                setIsCO2(!isCO2);
                if (isCO2 && particlesMesh) {
                    scene.remove(particlesMesh);
                    particlesMesh = null;
                } else if (!isCO2 && !particlesMesh) {
                    const particlesGeometry = new THREE.BufferGeometry();
                    const particlesCount = 5000;
                    const posArray = new Float32Array(particlesCount * 3);

                    for (let i = 0; i < particlesCount * 3; i++) {
                        posArray[i] = (Math.random() - 0.5) * 20;
                    }

                    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
                    const particlesMaterial = new THREE.PointsMaterial({
                        size: 0.005,
                        color: PARTICLE_COLOR,
                    });

                    particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
                    particlesMesh.position.z = -5;
                    scene.add(particlesMesh);
                }
            }
        };

        window.addEventListener('click', onMouseClick);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', onMouseClick);
            // mountRef.current.removeChild(renderer.domElement);
            if (currentMount) {
                currentMount.removeChild(renderer.domElement);
            }
            document.body.style.margin = '';
            document.body.style.overflow = '';
            document.documentElement.style.overflow = '';
        };
    }, [isCO2]);

    const handleContactClick = (e) => {
        e.preventDefault();
        window.open('https://cal.com/willpurvis/30min', '_blank');
    };

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'black',
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            left: 0,
            margin: 0,
            padding: 0
        }}>
            <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
            <div style={{
                position: 'absolute',
                bottom: '5%',
                left: '50%',
                transform: 'translateX(-50%)',
                color: 'white',
                textAlign: 'center',
                width: '100%'
            }}>
                <h1 style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: '4.5rem',
                    fontWeight: 700,
                    marginBottom: '0.5rem',
                    textShadow: '0 0 10px rgba(255,255,255,0.5)'
                }}>
                    Kairos Labs
                </h1>
                <div style={{
                    width: '60px',
                    height: '4px',
                    backgroundColor: 'white',
                    margin: '0.5rem auto'
                }}></div>
                {isCO2 ? (
                    <p style={{
                        fontFamily: "'Open Sans', sans-serif",
                        fontSize: '1.5rem',
                        fontWeight: 300,
                        marginTop: '0.5rem',
                        textShadow: '0 0 5px rgba(255,255,255,0.5)'
                    }}>
                        Catalysing change
                    </p>
                ) : (
                    <p style={{
                        fontFamily: "'Open Sans', sans-serif",
                        fontSize: '1.5rem',
                        fontWeight: 300,
                        marginTop: '0.5rem',
                        textShadow: '0 0 5px rgba(255,255,255,0.5)',
                        cursor: 'pointer'
                    }}
                        onClick={handleContactClick}>
                        Contact
                    </p>
                )}
            </div>
        </div>
    );
};

export default MoleculeLandingPage;